import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
// const ScholarshipAdminDemo = React.lazy(() => import("./ScholarshipAdminDemo"));
// const ScholarshipAppDemo = React.lazy(() => import("./ScholarshipAppDemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));


const technologies = [

    { h5: 'PHP', p: '', img: 'php.png' },
    { h5: 'Laravel', p: '', img: 'laravel10.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'Flutter', p: '', img: 'flutter.png' },

]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const mobileDrButtonName = ['Splash Screen', 'Home Page','Profile','Search' ,'Favorite Address', 'Ride Now', 'Apply Coupon', 'Auto','Sedan','Payment Method','Choose date', 'Search Driver','Ride Confirm','OTP','On the Way','Cancel Booking','Payment','Upcoming Rides','Completed Rides','Trips Summery','Cancelled Rides','Wallet','Compliant','Settings','Chat','Referral']
const mobileDrImageAddress = [
    '/assets/images/our-products/taxiApp/user/Splash Screen 4.webp',
    '/assets/images/our-products/taxiApp/user/Home Screen.webp',
    '/assets/images/our-products/taxiApp/user/User Profile.webp',
    '/assets/images/our-products/taxiApp/user/Ride Confirmed.webp',
    '/assets/images/our-products/taxiApp/user/Favourite Address.webp',
    '/assets/images/our-products/taxiApp/user/Ride Start & End Point.webp',
    '/assets/images/our-products/taxiApp/user/Apply Coupon.webp',
    '/assets/images/our-products/taxiApp/user/Auto Fare.webp',
    '/assets/images/our-products/taxiApp/user/Sedan Fare.webp',
    '/assets/images/our-products/taxiApp/user/Choose Payment Method.webp',
    '/assets/images/our-products/taxiApp/user/Add Date.webp',
    '/assets/images/our-products/taxiApp/user/Searching for Driver.webp',
    '/assets/images/our-products/taxiApp/user/Ride Confirmed.webp',
    '/assets/images/our-products/taxiApp/user/Vehicle & Driver Details.webp',
    '/assets/images/our-products/taxiApp/user/Driver Arrived.webp',
    '/assets/images/our-products/taxiApp/user/OTP to Start Ride.webp',
    '/assets/images/our-products/taxiApp/user/Payment Method.webp',
    '/assets/images/our-products/taxiApp/user/Upcoming Ride.webp',
    '/assets/images/our-products/taxiApp/user/Completed Rides.webp',
    '/assets/images/our-products/taxiApp/user/Ride Complete.webp',
    '/assets/images/our-products/taxiApp/user/Cancelled Ride.webp',
    '/assets/images/our-products/taxiApp/user/Wallet.webp',
    '/assets/images/our-products/taxiApp/user/Complaint.webp',
    '/assets/images/our-products/taxiApp/user/Settings.webp',
    '/assets/images/our-products/taxiApp/user/Admin Chat.webp',
    '/assets/images/our-products/taxiApp/user/Refferal.webp',
    
]

const mobileDriverButtonName = ['Splash Screen', 'Home Page','Profile','Car Info' ,'Upload Document', 'Bank Detail', 'Ride Request', 'Enter OTP','End Ride','Trip Summary','Ratings & Review', 'Completed Rides','Summary','Canceled Rides','Notification','Wallet','Withdraw','Add Money','Transfer Money From Wallet','Daily Earning Report','Weekly Earning Report','Earning Report by Date','SOS','Complaint','Payment Complaint','Setting','Change language','Dark Theme','Chat','Referral']
const mobileDriverImageAddress = [
    '/assets/images/our-products/taxiApp/driver/Splash Screen1.webp',
    '/assets/images/our-products/taxiApp/driver/Home Screen On Duty.webp',
    '/assets/images/our-products/taxiApp/driver/Profile Setup.webp',
    '/assets/images/our-products/taxiApp/driver/Vehicle Info.webp',
    '/assets/images/our-products/taxiApp/driver/Upload Documents.webp',
    '/assets/images/our-products/taxiApp/driver/Bank Detail.webp',
    '/assets/images/our-products/taxiApp/driver/Ride Request.webp',
    '/assets/images/our-products/taxiApp/driver/Enter OTP.webp',
    '/assets/images/our-products/taxiApp/driver/End Ride.webp',
    '/assets/images/our-products/taxiApp/driver/Fare Breakup.webp',
    '/assets/images/our-products/taxiApp/driver/Ratings & Review.webp',
    '/assets/images/our-products/taxiApp/driver/Completed Rides.webp',
    '/assets/images/our-products/taxiApp/driver/Fare Breakup.webp',
    '/assets/images/our-products/taxiApp/driver/Cancelled Rides.webp',
    '/assets/images/our-products/taxiApp/driver/Notification.webp',
    '/assets/images/our-products/taxiApp/driver/Wallet.webp',
    '/assets/images/our-products/taxiApp/driver/Withdraw Money.webp',
    '/assets/images/our-products/taxiApp/driver/Add Money in Wallet.webp',
    '/assets/images/our-products/taxiApp/driver/Transfer Money from Wallet.webp',
    '/assets/images/our-products/taxiApp/driver/Daily Earning Report.webp',
    '/assets/images/our-products/taxiApp/driver/Weekly Earning Report.webp',
    // '/assets/images/our-products/taxiApp/driver/Payment Complaint.webp',
    '/assets/images/our-products/taxiApp/driver/Earning Report by Date.webp',
    '/assets/images/our-products/taxiApp/driver/SOS.webp',
    '/assets/images/our-products/taxiApp/driver/Complaints.webp',
    '/assets/images/our-products/taxiApp/driver/Payment Complaint.webp',
    '/assets/images/our-products/taxiApp/driver/Settings.webp',
    '/assets/images/our-products/taxiApp/driver/Languages.webp',
    '/assets/images/our-products/taxiApp/driver/Dark Theme.webp',
    '/assets/images/our-products/taxiApp/driver/Admin Chat for Support.webp',
    '/assets/images/our-products/taxiApp/driver/Refferal.webp',
    
]

const userAppButtonNames = ['Dashboard', 'Driver Document', 'Driver Rating', 'Driver Report', 'Negative Pending Drivers', 'Approved Drivers','Rides','Cancelled Rides','Scheduled Rides','Notification','Onboarding','Owner Report','Rental Package','Service Location','Set Price - France', 'Set Price - World','User Report','Users Details','Vehical Made','Vehical Type','Withdrawal Request','Zone'];
const userAppImageAddress = [
    '/assets/images/our-products/taxiApp/admin/Dashboard.webp',
    '/assets/images/our-products/taxiApp/admin/Driver Document.webp',
    '/assets/images/our-products/taxiApp/admin/Driver Rating.webp',
    '/assets/images/our-products/taxiApp/admin/Driver Report.webp',
    '/assets/images/our-products/taxiApp/admin/Negative Pending Drivers.webp',
    '/assets/images/our-products/taxiApp/admin/Approved Drivers.webp',
    '/assets/images/our-products/taxiApp/admin/Rides.webp',
    '/assets/images/our-products/taxiApp/admin/Cancelled Rides.webp',
    '/assets/images/our-products/taxiApp/admin/Scheduled Rides.webp',
    '/assets/images/our-products/taxiApp/admin/Notification.webp',
    '/assets/images/our-products/taxiApp/admin/Onboarding.webp',
    '/assets/images/our-products/taxiApp/admin/Owner Report.webp',
    '/assets/images/our-products/taxiApp/admin/Rental Package.webp',
    '/assets/images/our-products/taxiApp/admin/Service Location.webp',
    '/assets/images/our-products/taxiApp/admin/Set Price - France.webp',
    '/assets/images/our-products/taxiApp/admin/Set Price - World.webp',
    '/assets/images/our-products/taxiApp/admin/User Report.webp',
    '/assets/images/our-products/taxiApp/admin/Users Details.webp',
    '/assets/images/our-products/taxiApp/admin/Vehical Made.webp',
    '/assets/images/our-products/taxiApp/admin/Vehical Type.webp',
    '/assets/images/our-products/taxiApp/admin/Withdrawal Request.webp',
    '/assets/images/our-products/taxiApp/admin/Zone.webp',
];

export default class TaxiApp extends Component {

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Taxi App portfolio || Noorisys Technologies Pvt Ltd' pageUrl='/taxi-app' metaTitle="Taxi App (Commission Based)" metaImage="/assets/images/portfolio/dp-portfolio-02.jpg"
                    metaDescription="" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb  titlehtml='Taxi App <br /> <span>(Commission Based Model)</span>' dataBlackOverlay={7} customColor='custom-scholarship-color' bgImage='bg_image--140'
                    p='Web / Android / iOS Application' pb='pb--120'
                    buttonTitle={'get a quote'}
                    openPopup={true} />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-scholarship-color">Taxi App (Commission Based)</h3>
                                        <p className="justifypara">This taxi app provides a seamless ride-booking experience, connecting users to nearby
                                            drivers for convenient transport. It includes user-friendly features for ride scheduling,
                                            tracking, fare calculation, ensuring safety and reliability for both users and drivers.
                                            In this app admin will earn commission from each ride.

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ptb--90">
                        <div className="container">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <PlatformUsed color='custom-scholarship-color' items={technologies} />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-scholarship-color">User App Functionalities (Android and iOS): </h3>
                                        <p className="justifypara">This taxi app allows users to book a ride instantly, view estimated fares, and track their
                                            driver in real-time. The user can book a taxi by entering their pick-up and drop-off
                                            locations, track the driver's arrival and make payments through the app.

                                        </p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Registration and Login</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Search Vehicles
                                            </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Instant Ride Booking
                                            </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Schedule Ride
                                            </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Estimated Price </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Driver Details
                                            </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Track Rides
                                            </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Pay from available payment method</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Ride Summary</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Rating and Reviews</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Wallet</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Add favourite addresses</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Ride History</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Chat</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-scholarship-color">Driver App Functionalities (Android and iOS)
                                            :  </h3>
                                        <p className="justifypara">This app helps drivers receive ride requests, navigate to passengers efficiently and earn
                                            through a transparent fare system.

                                        </p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Login</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Signup</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Add Vehicle</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Customer Details</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Track Rides</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Ride History</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Chat</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Wallet</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Withdraw Money</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Earnings </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Ride Summary</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Rating and Review</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-scholarship-color">Admin Functionalities :  </h3>
                                        <p className="justifypara">The admin manages users and drivers, monitors rides, manages price and ensure smooth operation.
                                        </p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Dashboard</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Users</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Drivers</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Service Locations</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Vehicle Types</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Scheduled Rides</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Instant Rides</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Price</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Chat with Customers and Drivers</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Reviews </li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />View Payments</li>
                                            <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Withdrawals</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}
                <div className="rn-portfolio-area bg_color--1 ptb--10" >
                    {/* <div className="container">
                    </div> */}
                    <div className="rn-portfolio-area bg_color--1 pt--10 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p={`This taxi app allows users to book a ride instantly, view estimated fares, and track their driver in real-time. The user can book a taxi by entering their pick-up and drop-off locations, track the driver's arrival and make payments through the app.`}
                                    buttonNames={mobileDrButtonName}
                                    image={mobileDrImageAddress}
                                    alt="Taxi App"
                                    color='sesa-pay'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Driver App'
                                    p={`This app helps drivers receive ride requests, navigate to passengers efficiently and earn through a transparent fare system.`}
                                    buttonNames={mobileDriverButtonName}
                                    image={mobileDriverImageAddress}
                                    alt="Taxi App"
                                    color='sesa-pay'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                        <div className="container">
                            <div className="row">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <CommonProductWeb
                                        h3='Admin Panel'
                                        p='The admin manages users and drivers, monitors rides, manages price and ensure smooth operation.'
                                        buttonNames={userAppButtonNames}
                                        image={userAppImageAddress}
                                        alt='digital bank user'
                                        color='sesa-pay'
                                    />                                    
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                <CommonPortfolioButtons class='btn-sesapay' text='View All Our Projects' padding='pb--40' />
                {/* End portfolio Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}