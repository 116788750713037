import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import ProductCommonApp from "../portfolio/ProductCommonAppdemo";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const CommonPortfolioButtons = React.lazy(() => import("../component/CommonPortfolioButton"));
const CoursierDelivrable = React.lazy(() => import("../portfolio/CoursierDeliverables"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const deliverables = [
    { item_bg: 'android.png', item_icon: 'user-tourism.png', h3: 'User App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'user-tourism.png', h3: 'User App', h4: 'iOS' },
    { item_bg: 'android.png', item_icon: 'user-tourism.png', h3: 'Driver App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'admin-tourism.png', h3: 'Driver App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'admin-tourism.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },
    { h5: 'Laravel', p: 'Admin Panel Development', img: 'laravel.png' },

]
// const similarapps = [
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect ', p: 'Pharmacist Marketplace' },
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    // { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
]

const SocialShare = [
    { Social: <FaGlobe />, link: ' https://umrahplus.app/package-list ' }
];



const userAppButtonNames = ['Signup', 'Login', 'Landing Screen', 'Select Vehicle', 'Driver Details', 'Driver Arrived', 'Start Journey', 'End Journey', 'Menu', 'Chat', 'Ride History'];
const userAppImageAddress = [
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-signup.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-login.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-landing.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-select.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-driver.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-arrived.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-start.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-end.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-menu.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-chat.webp',
    '/assets/images/our-products/taxi-app-subscription-based/user/taxi-history.webp',
];
const driverAppButtonNames = ['Signup Personal Information', 'Upload Document', 'Upload Vehicle Information', 'Login', 'Home', 'Accept Ride', 'Arrived On Location', 'Start Ride', 'Complete Ride', 'Menu', 'Profile', 'Chat', 'Ride History'];
const driverAppImageAddress = [
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-signup.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-signup-document.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-vehicle-info.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-login.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-landing.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-accept.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-arrived.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-start.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-complete.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-menu.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-profile.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-chat.webp',
    '/assets/images/our-products/taxi-app-subscription-based/driver/driver-history.webp',
];

const adminButtonNames = ['Dashboard', 'Manage Users', 'Manage Drivers', 'Manage Subscriptions', 'Manage Vehicle Types', 'Manage Rides'];
const adminImageAddress = [
    '/assets/images/our-products/taxi-app-subscription-based/admin/dashboard.webp',
    '/assets/images/our-products/taxi-app-subscription-based/admin/manage-users.webp',
    '/assets/images/our-products/taxi-app-subscription-based/admin/manage-drivers.webp',
    '/assets/images/our-products/taxi-app-subscription-based/admin/manage-subscription.webp',
    '/assets/images/our-products/taxi-app-subscription-based/admin/manage-veicle-types.webp',
    '/assets/images/our-products/taxi-app-subscription-based/admin/manage-rides.webp',
];
class TaxiAppSubscriptionBasedProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Umrah Plus || Noorisys Technologies Pvt Ltd' pageUrl='/tourism-company-management-system' metaTitle="Umrah Plus" metaImage="/assets/images/portfolio/dp-portfolio-01.jpg"
                    metaDescription="Umrah Plus is a platform to book tour packages, meals, local transportation and guides for umrah pilgrims in Makkah and Madinah. Contact us to make your own tourism management system." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    titlehtml='Taxi App <br /> <span>(Subscription Based Model)</span>'
                    dataBlackOverlay={7} bgImage='bg_image--140'
                    customColor='custom-scholarship-color'
                    p='Web / Android / iOS Application'
                    socialShare={SocialShare}
                    pb={'pb--120'}
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-scholarship-color">Taxi App (Subscription Based Model)</h3>
                                        <p className="justifypara">This taxi app allows users to conveniently book rides, drivers to accept requests and
                                            admins to manage operations, providing a seamless transportation service for all.</p>
                                        <p className="justifypara"> In this application, admin will earn money from subscription of drivers. There is no
                                            payment from customers inside the app. They will pay to drivers outside the system.</p>
                                        <h4 className="title">User App Functionalities (Android and iOS)</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Login</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Signup</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Search Vehicles</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Select Vehicle Type</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />View Driver Details</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Track Rides</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Ride History</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Chat</li>
                                        </ul>
                                        <h4 className="title">Driver App Functionalities (Android and iOS)</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Login</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Signup</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Add Vehicle</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />View Customer Details</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Track Rides</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Ride History</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Chat</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Subscription</li>
                                        </ul>
                                        <h4 className="title">Admin Functionalities</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Dashboard</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Manage Users</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Manage Drivers</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Manage Subscriptions</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Manage Vehicle Types</li>
                                            <li className="justifypara"><FiCheck color={'#F2C326'} />Manage Rides</li>
                                        </ul>

                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-taxi-subscription text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                {/* <div className="rn-portfolio-area bg_color--1 ptb--60"
                    style={{ backgroundColor: '#F2C326' }}
                >
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CoursierDelivrable title={'5 Deliverables'} items={deliverables} class={'taxi-subscription'} value={[5, 4, 3]} />
                        </React.Suspense>
                    </div>
                </div> */}
                <div className="d-flex justify-content-center align-items-center pt--30">
                    <button className="get-a-quote btn-taxi-subscription text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div>
                <div className="pt--60">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-scholarship-color' items={technologies} />
                        </React.Suspense>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-taxi-subscription text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p={`The user can book a taxi by entering their pick-up and drop-off locations, track the 
driver's arrival and make payments outside the app.`}
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='umrah user app'
                                    color='taxi-subscription'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-taxi-subscription text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Driver App'
                                    p={` The driver can accept ride requests, navigate to the pick-up and drop-off points, and 
receive payments outside the app`}
                                    buttonNames={driverAppButtonNames}
                                    image={driverAppImageAddress}
                                    alt='umrah user panel'
                                    color='taxi-subscription'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-taxi-subscription text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p={`The admin manages users and drivers, monitors rides, manage subscriptions and 
ensures smooth operation.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='umrah admin'
                                    color='taxi-subscription'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-taxi-subscription text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-scholarship-color' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-taxi-subscription' text='View All Our Products' link='/our-products' />
                {/* </div> */}
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default TaxiAppSubscriptionBasedProduct;
